import { useEffect, useState } from 'react';

export const useScroll = () => {
  const [offsetY, setOffsetY] = useState(0);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      setOffsetY(window.scrollY);
    });
  }, []);

  return offsetY;
};
