import type { FC, ReactNode } from 'react';

interface FlexProps {
  children: ReactNode;
  justifyContent?: string;
}

const Flex: FC<FlexProps> = ({
  children,
  justifyContent = 'spaceBetween'
}) => {
  return (
    <div
      className='flex'
      style={{ justifyContent: justifyContent }}
    >
      {children}
    </div>
  );
};

export default Flex;
