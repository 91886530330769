import { useMediaQueries } from 'src/hooks/useMediaQueries';
import { useScroll } from 'src/hooks/useScroll';
import Navbar from './Navbar';
import MobileNavigation from './MobileNavbar';

const TopBar = () => {
  const isMdUp = useMediaQueries('mediumUp');
  const offsetY = useScroll();

  return isMdUp ? <Navbar offsetY={offsetY} /> : <MobileNavigation offsetY={offsetY} />;
};

export default TopBar;
