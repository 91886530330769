import type { FC } from 'react';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import NavLogo from 'src/assets/nav-logo.png';
import styles from 'src/styles/Navbar.module.scss';

interface NavbarProps {
  offsetY: number;
}

const Navbar: FC<NavbarProps> = ({ offsetY }) => {
  const logoVariants = {
    hidden: { opacity: 0, rotate: '180deg' },
    visible: {
      opacity: 1,
      rotate: 0,
      transition: {
        duration: 1, type: 'spring', stiffness: 100, delay: .7
      }
    }
  };

  return (
    <nav className={clsx(styles.navbar, offsetY > 0 && styles.backgroundNavbar)}>
      <motion.img
        src={NavLogo}
        alt='MegaByte.net logo'
        width={60}
        height={60}
        initial='hidden'
        animate='visible'
        variants={logoVariants}
      />
      <ul className={styles.navContent}>
        <li className={styles.navItem}>
          <a href='#home'>
              Strona główna
          </a>
        </li>
        <li className={styles.navItem}>
          <a href='#about'>
              O mnie
          </a>
        </li>
        <li className={styles.navItem}>
          <a href='#skills'>
              Technologie
          </a>
        </li>
        <li className={styles.navItem}>
          <a href='#work'>
                Moje projekty
          </a>
        </li>
        <li className={styles.navItem}>
          <a href='#contact'>
                Kontakt
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
