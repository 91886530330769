import type { FC } from 'react';
import { useEffect } from 'react';
import { motion } from 'framer-motion';
import { useScrollLock } from 'src/hooks/useLockScroll';
import styles from 'src/styles/Loader.module.scss';

interface LoaderProps {
  open: boolean;
}

const Loader: FC<LoaderProps> = ({ open = true }) => {
  const { lockScroll, unlockScroll } = useScrollLock();

  useEffect(() => {
    if (open) {
      lockScroll();
    } else  {
      unlockScroll();
    }
  }, [open]);

  return (
    <>
      {open && (
        <div className={styles.loaderWrapper}>
          <motion.div
            className={styles.loader}
            initial={{ rotate: 0, x: '-50%', y: '-50%' }}
            animate={{ rotate: '360deg', x: '-50%', y: '-50%'}}
            transition={{ repeat: Infinity, duration: 1.5 }}
          />
        </div>
      )}
    </>
  );
};

export default Loader;
