import Home from 'src/views/Home';
import About from 'src/views/About';
import Skills from 'src/views/Skills';
import Work from 'src/views/Work';
import Contact from 'src/views/Contact';
import { Footer, TopBar } from 'src/components';

function App() {
  return (
    <>
      <TopBar />
      <Home />
      <About />
      <Skills />
      <Work />
      <Contact />
      <Footer />
    </>
  );
}

export default App;
