import { useState } from 'react';
import { useFormik } from 'formik';
import emailJs from '@emailjs/browser';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import { Loader, Title } from 'src/components';
import styles from 'src/styles/Contact.module.scss';
import clsx from 'clsx';

const Contact = () => {
  const [sendingEmail, setSendingEmail] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { REACT_APP_TEMPLATE_ID: templateID, REACT_APP_PUBLIC_KEY: publicKey } = process.env;


  const formik = useFormik({
    initialValues: {
      email: '',
      subject: '',
      message: ''
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email('Nieprawidłowy adres mailowy').required('Adres mailowy jest wymagany'),
      subject: Yup.string().required('Temat wiadomości jest wymagany').max(100, 'Temat jest zbyt długi'),
      message: Yup.string().required('Treść wiadomości jest wymagana').max(5000, 'Wiadomość zbyt długa')
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        setSendingEmail(true);
        await emailJs.send('default_service', templateID!, values, publicKey);
        resetForm();
        enqueueSnackbar('Message sent successfully', { variant: 'success' });
      } catch (error) {
        enqueueSnackbar('Something went wrong', { variant: 'error' });
      } finally {
        setSendingEmail(false);
      }
    }
  });

  const showError = (
    fieldName: keyof typeof formik.initialValues
  ): boolean => Boolean(formik.touched[fieldName] && formik.errors[fieldName]);


  return (
    <section
      className={styles.contactPage}
      id='contact'
    >
      <Title
        text="Kontakt"
        whiteText={true}
      />

      <form
        onSubmit={formik.handleSubmit}
        className={styles.form}
      >
        <div className={styles.inputGroup}>
          <input
            type='email'
            name='email'
            placeholder='Email'
            onChange={formik.handleChange}
            className={clsx(styles.input, showError('email') && styles.inputError)}
            value={formik.values.email}
          />
          {showError('email') && (
            <p className={styles.formError}>{formik.errors.email}</p>
          )}
        </div>

        <div className={styles.inputGroup}>
          <input
            type='text'
            name='subject'
            placeholder='Temat'
            onChange={formik.handleChange}
            className={clsx(styles.input, showError('subject') && styles.inputError)}
            value={formik.values.subject}
          />
          {showError('subject') && (
            <p className={styles.formError}>{formik.errors.subject}</p>
          )}
        </div>
        <div className={styles.inputGroup}>
          <textarea
            name='message'
            placeholder='Wiadomość'
            onChange={formik.handleChange}
            rows={7}
            className={clsx(styles.textarea, showError('message') && styles.inputError)}
            value={formik.values.message}
          />
          {showError('message') && (
            <p className={styles.formError}>{formik.errors.message}</p>
          )}
        </div>
        <div className='flex'>
          <button
            type='submit'
            className={styles.confirmationButton}
          >
              Wyślij
          </button>
        </div>
      </form>
      <Loader open={sendingEmail} />
    </section>
  );
};

export default Contact;
