import { useEffect, useRef } from 'react';
import type { Dispatch, FC, MouseEvent, SetStateAction } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { AiOutlineClose } from 'react-icons/ai';
import styles from 'src/styles/Modal.module.scss';
import buttonStyles from 'src/styles/Buttons.module.scss';
import WorkOverview from './WorkOverview';
import type { Project } from 'src/types/project';
import type { Nullable } from 'src/types/generic';
import { AnimatePresence, motion } from 'framer-motion';
import { useScrollLock } from '../hooks/useLockScroll';
import { Title } from './index';
import Flex from './Flex';
import { useMediaQueries } from '../hooks/useMediaQueries';

interface WorkModalProps {
  open: boolean;
  setOpenProject: Dispatch<SetStateAction<{ isOpen: boolean, project: Nullable<Project> }>>;
  project: Project;
}

const WorkModal: FC<WorkModalProps> = ({ open, setOpenProject, project }) => {
  const ref = useRef(null);
  const { lockScroll, unlockScroll} = useScrollLock();
  const isMdUp = useMediaQueries('mediumUp');

  useEffect(() => {
    if (open) {
      lockScroll();
    } else  {
      unlockScroll();
    }
  }, [open]);

  const handleClose = (e: MouseEvent<HTMLDivElement>) => {
    if (e.target === ref.current) {
      setOpenProject({ isOpen: false, project: null });
    }
  };

  const modalVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: .3,
        ease: 'easeOut'
      }
    },
  };

  const modalContentVariants = {
    hidden: { x: '-50%', y: '-150%', opacity: 0 },
    visible: {
      x: '-50%', y: '-50%', opacity: 1,
      transition: {
        duration: 1, delay: .2, type: 'spring', stiffness: 60
      }
    },
  };

  return (
    <AnimatePresence>
      {open && (
        <motion.div
          className={styles.modal}
          ref={ref}
          onClick={(e) => handleClose(e)}
          initial="hidden"
          animate="visible"
          exit="hidden"
          variants={modalVariants}
        >
          <motion.div
            className={styles.modalContent}
            variants={modalContentVariants}
          >
            <PerfectScrollbar
              options={{
                suppressScrollX: true
              }}
            >
              <div className={styles.closeModal}>
                <button
                  className={buttonStyles.iconButton}
                  onClick={() => setOpenProject({ isOpen: false, project: null })}
                >
                  <AiOutlineClose />
                </button>
              </div>
              <div className={styles.modalBody}>
                <Title text={project.name} />

                <Flex>
                  <div className={styles.overviewColumn}>
                    <WorkOverview project={project} />
                  </div>

                  <div className={styles.descriptionColumn}>
                    {project.description}
                  </div>

                  <div className={styles.technologiesColumn}>
                    <Flex justifyContent={isMdUp ? 'flex-start' : 'center'}>
                      {project.technologies?.map((tech) => (
                        <tech.icon
                          key={tech.id}
                          className={styles.modalSkill}
                        />
                      ))}
                    </Flex>
                  </div>

                  <div className={styles.actionsColumn}>
                    <a
                      className={buttonStyles.modalButton}
                      href={project.url}
                      target='_blank'
                      rel='noreferrer'
                    >
                      Przejdź do strony
                    </a>
                  </div>
                </Flex>
              </div>
            </PerfectScrollbar>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default WorkModal;
