import { useEffect, useState } from 'react';

type Breakpoint = 'small' | 'medium' | 'large' | 'smallUp' | 'mediumUp';

export const useMediaQueries = (breakpoint: Breakpoint) => {
  const [windowWidth, setWindowWidth] = useState<number>();

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const isScreenSize = () => {
    switch (breakpoint) {
      case 'small':
        return windowWidth && windowWidth > 600 && windowWidth <= 950;
      case 'smallUp':
        return windowWidth && windowWidth > 600;
      case 'medium':
        return windowWidth && windowWidth > 950 && windowWidth <= 1200;
      case 'mediumUp':
        return windowWidth && windowWidth > 950;
      case 'large':
        return windowWidth && windowWidth > 1200;
      default:
        return true;
    }
  };

  return isScreenSize();
};
