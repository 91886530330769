import type { FC } from 'react';
import clsx from 'clsx';
import styles from 'src/styles/Title.module.scss';

interface TitleProps {
  text: string;
  whiteText?: boolean
}

const Title: FC<TitleProps> =({ text, whiteText }) => (
  <div className={clsx(styles.title, whiteText && styles.whiteTitle)}>
    <h2 >
      {text}
    </h2>
  </div>
);

export default Title;

