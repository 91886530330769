import type { Project } from 'src/types/project';
import type { Nullable } from 'src/types/generic';
import { useState } from 'react';
import { useMediaQueries } from 'src/hooks/useMediaQueries';
import { projects } from 'src/constants/projects';
import { Container, Flex, Title, WorkModal } from 'src/components';
import { ReactComponent as WorkPageOverlay } from 'src/assets/work-page-overlay.svg';
import styles from 'src/styles/Work.module.scss';


const Works = () => {
  const [openProject, setOpenProject] = useState<{ isOpen: boolean, project: Nullable<Project> }>({
    isOpen: false,
    project: null
  });
  const isSmUp = useMediaQueries('smallUp');

  return (
    <section
      className={styles.workPage}
      id='work'
    >
      <Title text="Moje Projekty" />

      <Container>
        <Flex
          justifyContent={isSmUp ? 'flex-start' : 'center'}
        >
          {projects.map((project) => (
            <div
              key={project.id}
              className={styles.workItem}
              onClick={() => setOpenProject({ isOpen: true, project })}
            >
              <img
                src={project.thumbnail}
                alt={project.name}
              />
            </div>
          ))}
        </Flex>
      </Container>

      <WorkModal
        open={openProject.isOpen}
        setOpenProject={setOpenProject}
        project={openProject.project!}
      />

      <WorkPageOverlay className={styles.overlay} />
    </section>
  );
};

export default Works;
