import { AiFillGithub, AiFillLinkedin, AiOutlineMail } from 'react-icons/ai';
import Flex from './Flex';
import styles from 'src/styles/Footer.module.scss';

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <Flex justifyContent='center'>
        <a
          href='mailto:mariuszbartnik@megabyte.net.pl'
          className={styles.iconLink}
        >
          <AiOutlineMail />
        </a>
        <a
          href='https://github.com/MariuszBartnik?tab=repositories'
          target='_blank'
          rel='noreferrer'
          className={styles.iconLink}
        >
          <AiFillGithub />
        </a>
        <a
          href='https://www.linkedin.com/in/mariusz-bartnik-43a7b523a/'
          target='_blank'
          rel='noreferrer'
          className={styles.iconLink}
        >
          <AiFillLinkedin />
        </a>
      </Flex>
      <div className={styles.copyright}>Mariusz Bartnik - MegaByte.net &copy; 2022</div>
    </footer>
  );
};

export default Footer;
