import type { FC } from 'react';
import type { Project } from 'src/types/project';
import styles from 'src/styles/Modal.module.scss';

interface WorkOverviewProps {
  project: Project
}

const WorkOverview: FC<WorkOverviewProps> = ({ project }) => {
  return (
    <div className={styles.overviewImgWrapper}>
      <img
        src={project.overviewImg}
        alt={project.name}
      />
    </div>
  );
};

export default WorkOverview;
