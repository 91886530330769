import Slider from 'react-slick';
import { useMediaQueries } from 'src/hooks/useMediaQueries';
import { skills } from 'src/constants/skills';
import { Title } from 'src/components';
import styles from 'src/styles/Skills.module.scss';

const Skills = () => {
  const isMdUp = useMediaQueries('mediumUp');

  const sliderOptions = {
    infinite: true,
    slidesToShow: isMdUp ? 6 : 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 1000,
    cssEase: 'linear'
  };

  return (
    <section
      className={styles.skillsPage}
      id={'skills'}
    >
      <Title text="Technologie" />

      <div className={styles.slider}>
        <Slider {...sliderOptions}>
          {skills.map((skill) => (
            <skill.icon
              key={skill.id}
              className={styles.skill}
            />
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default Skills;
