import { Flex } from 'src/components';
import HomePageImg from 'src/assets/home-page.png';
import { ReactComponent as HomePageOverlay} from 'src/assets/home-page-overlay.svg';
import { ReactComponent as LogoBackground} from 'src/assets/logo-background.svg';
import styles from 'src/styles/Home.module.scss';
import buttonsStyles from 'src/styles/Buttons.module.scss';

const Home = () => {
  return (
    <section
      className={styles.mainPage}
      id="home"
    >
      <LogoBackground className={styles.logoBackground} />

      <Flex>
        <div className={styles.imgColumn}>
          <img
            src={HomePageImg}
            alt='Web development'
            className={styles.homePageImg}
          />
        </div>
        <div className={styles.mainColumn}>
          <div>
            <h4 className={styles.mainPageSubtitle}>
              Web developer
            </h4>
            <h2 className={styles.mainPageTitle}>
              Mariusz Bartnik
            </h2>
          </div>

          <div className={styles.actionButtons}>
            <div>
              <a
                className={buttonsStyles.primaryButton}
                href='#work'
              >
                Przykładowe projekty
              </a>
            </div>
            <div>
              <a
                className={buttonsStyles.secondaryButton}
                href='#contact'
              >
                Skontaktuj się ze mną
              </a>
            </div>
          </div>
        </div>
      </Flex>
      <HomePageOverlay className={styles.overlay} />
    </section>
  );
};

export default Home;
