import type { FC } from 'react';
import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import { useScrollLock } from 'src/hooks/useLockScroll';
import { FiMenu } from 'react-icons/fi';
import { AiOutlineClose } from 'react-icons/ai';
import NavLogo from 'src/assets/nav-logo.png';
import styles from 'src/styles/Navbar.module.scss';
import buttonStyles from 'src/styles/Buttons.module.scss';

interface MobileNavbarProps {
  offsetY: number;
}

const MobileNavbar: FC<MobileNavbarProps> = ({ offsetY }) => {
  const [menuOpen, toggleMenu] = useState<boolean>(false);
  const { lockScroll, unlockScroll } = useScrollLock();

  useEffect(() => {
    if (menuOpen) {
      lockScroll();
    } else  {
      unlockScroll();
    }
  }, [menuOpen]);

  const logoVariants = {
    hidden: { opacity: 0, rotate: '180deg' },
    visible: {
      opacity: 1,
      rotate: 0,
      transition: {
        duration: 1, type: 'spring', stiffness: 100, delay: .7
      }
    }
  };

  const navContentVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { duration: .3, ease: 'easeOut' }
    }
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: .1
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, x: 300 },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        duration: .2,
        type: 'spring',
        stiffness: 60
      }
    }
  };

  return(
    <nav
      className={clsx(styles.navbar, offsetY > 0 && styles.backgroundNavbar)}
    >
      <motion.img
        src={NavLogo}
        alt='MegaByte.net logo'
        width={60}
        height={60}
        initial='hidden'
        animate='visible'
        variants={logoVariants}
      />
      <div>
        <button
          className={clsx(buttonStyles.iconButton, buttonStyles.whiteIconButton)}
          onClick={() => toggleMenu(true)}
        >
          <FiMenu />
        </button>
      </div>
      <AnimatePresence>
        {menuOpen && (
          <motion.div
            className={styles.mobileNavContent}
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={navContentVariants}
          >
            <button
              className={clsx(buttonStyles.iconButton, buttonStyles.closeMobileNav)}
              onClick={() => toggleMenu(false)}
            >
              <AiOutlineClose/>
            </button>
            <motion.ul
              variants={containerVariants}
            >
              <motion.li
                className={styles.mobileNavItem}
                variants={itemVariants}
              >
                <a
                  href='#home'
                  onClick={() => toggleMenu(false)}
                >
                  Strona główna
                </a>
              </motion.li>
              <motion.li
                className={styles.mobileNavItem}
                variants={itemVariants}
              >
                <a
                  href='#about'
                  onClick={() => toggleMenu(false)}
                >
                  O mnie
                </a>
              </motion.li>
              <motion.li
                className={styles.mobileNavItem}
                variants={itemVariants}
              >
                <a
                  href='#skills'
                  onClick={() => toggleMenu(false)}
                >
                  Technologie
                </a>
              </motion.li>
              <motion.li
                className={styles.mobileNavItem}
                variants={itemVariants}
              >
                <a
                  href='#work'
                  onClick={() => toggleMenu(false)}
                >
                  Moje projekty
                </a>
              </motion.li>
              <motion.li
                className={styles.mobileNavItem}
                variants={itemVariants}
              >
                <a
                  href='#contact'
                  onClick={() => toggleMenu(false)}
                >
                  Kontakt
                </a>
              </motion.li>
            </motion.ul>
          </motion.div>
        )}
      </AnimatePresence>
    </nav>
  );
};

export default MobileNavbar;
