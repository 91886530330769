import { Skill } from 'src/types/skill';
import { FaCss3, FaSass } from 'react-icons/fa';
import {
  SiAngular,
  SiExpress,
  SiHtml5,
  SiJavascript,
  SiMaterialui,
  SiMongodb,
  SiNestjs,
  SiNodedotjs,
  SiPostgresql,
  SiPwa,
  SiReact,
  SiRedux,
  SiTailwindcss,
  SiTypescript,
  SiWebpack
} from 'react-icons/si';

export const skills: Skill[] = [
  {id: 'html', name: 'HTML', icon: SiHtml5},
  {id: 'css', name: 'CSS', icon: FaCss3},
  {id: 'js', name: 'JavaScript', icon: SiJavascript},
  {id: 'ts', name: 'TypeScript', icon: SiTypescript},
  {id: 'react', name: 'React', icon: SiReact},
  {id: 'redux', name: 'Redux', icon: SiRedux},
  {id: 'angular', name: 'Angular', icon: SiAngular},
  {id: 'sass', name: 'Sass', icon: FaSass},
  {id: 'materialUi', name: 'Material UI', icon: SiMaterialui},
  {id: 'nodeJs', name: 'Node.js', icon: SiNodedotjs},
  {id: 'express', name: 'Express', icon: SiExpress},
  {id: 'nestJs', name: 'Nest JS', icon: SiNestjs},
  {id: 'mongoDb', name: 'Mongo DB', icon: SiMongodb},
  {id: 'postgreSql', name: 'PostgreSQL', icon: SiPostgresql},
  {id: 'pwa', name: 'PWA', icon: SiPwa},
  {id: 'webpack', name: 'Webpack', icon: SiWebpack},
  {id: 'tailwind', name: 'TailwindCss', icon: SiTailwindcss}
];
