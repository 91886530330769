import { Container, Flex, Title } from 'src/components';
import AboutMeImage from 'src/assets/about-me.png';
import { ReactComponent as AboutPageOverlay } from 'src/assets/about-page-overlay.svg';
import styles from 'src/styles/About.module.scss';

const About = () => (
  <section
    className={styles.aboutPage}
    id="about"
  >
    <Title text='O Mnie' />

    <Container>
      <Flex>
        <div className={styles.imgColumn}>
          <img
            className={styles.image}
            src={AboutMeImage}
            alt='me'
          />
        </div>
        <div className={styles.textColumn}>
          <p>
            Z wykształcenia historyk, z zawodu programista. Od roku współpracuję z firmą Code Concept, tworząc aplikacje
            webowe.
          </p>

          <p>
            W swojej pracy zajmuję stanowisko full stack web developera, co oznacza, że potrafię stworzyć zarówno
            warstwę frontendową aplikacji, jak i funkcjonalność, która jest wykonywana po stronie serwera. Dzięki tym
            umiejętnościom jestem w stanie samodzielnie wykonać dla Państwa zarówno prostą stronę internetową, jak i
            bardziej skomplikowaną aplikację.
          </p>

          <p>
            Tworząc strony i aplikacje korzystam z języków Javascript i Typescript, a także z szeregu innych technologii.
            Wszystkie wykorzystywane przeze mnie narzędzia należą do najnowszych i najlepszych technologii, co daje
            gwarancję, że Pańswta strona lub aplikacja będzie działała bez niespodziewanych problemów.
          </p>

          <p>
            W pracy wykazuję się profesjonalnym podejściem do klientów. Cechuję mnie rzetelność, terminowość i elastycność,
            dlatego możecie być Państwo pewni, że zlecona praca zostanie wykonana w terminie z najwyższą dbałością o
            najdrobniejsze szczegóły.
          </p>
        </div>
      </Flex>
    </Container>
    <AboutPageOverlay className={styles.overlay} />
  </section>
);

export default About;
