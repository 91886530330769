import CoffeeOrTeaThumbnail from 'src/assets/projects/coffeeOrTea.png';
import DeliciousTasteThumbnail from 'src/assets/projects/deliciousTaste.png';
import GoodOldGamesThumbnail from 'src/assets/projects/goodOldGames.png';
import CoffeeOrTea from 'src/assets/projects/coffeeOrTeaPage.png';
import DeliciousTaste from 'src/assets/projects/deliciousTastePage.png';
import GoodOldGames from 'src/assets/projects/goodOldGamesPage.png';
import { SiExpress, SiHtml5, SiNodedotjs, SiReact, SiWebpack } from 'react-icons/si';
import { FaCss3, FaSass } from 'react-icons/fa';
import type { Project } from 'src/types/project';

export const projects: Project[] = [
  {
    id: 'coffeeOrTea',
    name: 'Coffee or Tea',
    thumbnail: CoffeeOrTeaThumbnail,
    overviewImg: CoffeeOrTea,
    description: 'Strona internetowa stworzona z myślą o restauracji lub bistro. Projekt może zostawć wykorzystany ' +
      'jako statyczna strona internetowa, lub aplikacja pozwalająca na rezerwację stolików przez internet. Istnieje ' +
      'możliwość połączenia jej z systemem CMS w celu łatwiejszego publikowania nowych treści na stronie.',
    technologies: [
      { id: 'html', name: 'HTML', icon: SiHtml5 },
      { id: 'css', name: 'CSS', icon: FaCss3 },
      { id: 'sass', name: 'Sass', icon: FaSass },
      { id: 'webpack', name: 'Webpack', icon: SiWebpack }
    ],
    url: 'https://coffee-or-tea.firebaseapp.com/'
  },
  {
    id: 'deliciousTaste',
    name: 'Delicious Taste',
    thumbnail: DeliciousTasteThumbnail,
    overviewImg: DeliciousTaste,
    description: 'Aplikacja służąca do wyszukiwania restauracji w miastach wskazanych przez użytkownika. Aplikacje ' +
      'wykorzystuje dane dostarczane przez Zomato API - bazę danych zawierającą informacje dotyczacych restauracji ' +
      'w różnych krajach na całym świecie.',
    technologies: [
      { id: 'react', name: 'React', icon: SiReact },
      { id: 'nodeJs', name: ' Node.js', icon: SiNodedotjs },
      { id: 'express', name: 'Express.js', icon: SiExpress }
    ],
    url: 'https://zomato-api-restaurants.herokuapp.com/'
  },
  {
    id: 'goodOldGames',
    name: 'Good Old Games',
    thumbnail: GoodOldGamesThumbnail,
    overviewImg: GoodOldGames,
    description: 'Strona internetowa zawierająca kilka starych gier z czasów mojego dzieciństwa. Projekt stanowił ' +
      'ciekawe wyzwanie w początkach pracy z językiem Javascript. Wszystkie gry oraz pozostałe interaktywne elementy ' +
      'strony zostały napisane przy pomocy Vanilla Javascript.',
    technologies: [
      { id: 'html', name: 'HTML', icon: SiHtml5 },
      { id: 'css', name: 'CSS', icon: FaCss3 },
      { id: 'js', name: 'Javascript', icon: FaSass },
    ],
    url: 'https://good-old-games.firebaseapp.com/index.html'
  },
];
